import { RETRIEVE_CLINIQUES } from "../actions/types";

const initialState = {
    cliniques: []
};

function cliniqueReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case RETRIEVE_CLINIQUES:
            return { ...state, cliniques: payload };
        default:
            return state;
    }
};

export default cliniqueReducer;