import { RETRIEVE_COMMUNES } from "../actions/types";

const initialState = {
    communes: []
};

function communeReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case RETRIEVE_COMMUNES:
            return { ...state, communes: payload };
        default:
            return state;
    }
};

export default communeReducer;