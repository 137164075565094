import React from 'react';
import Copyright from "./Copyright";
import FooterLeftWidget from "./FooterLeftWidget";
import FooterQuickLinkWidget from "./FooterQuickLinkWidget";
import FooterCategoryWidget from "./FooterCategoryWidget";
import FooterContactWidget from "./FooterContactWidget";
import sectiondata from "../../../store/store";
import { FaBehance, FaDribbble, FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

var footerdata = {
    footerlogo: '',
    footerdesc: 'Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros',
    sociallinks: [
        {
            icon: <FaFacebookF />,
            url: 'https://facebook.com'
        },
        {
            icon: <FaTwitter />,
            url: 'https://twitter.com'
        },
        {
            icon: <FaLinkedinIn />,
            url: 'https://linkedin.com'
        },
        {
            icon: <FaDribbble />,
            url: 'https://dribbble.com'
        },
        {
            icon: <FaBehance />,
            url: 'https://behance.com'
        },
    ]
    /* footerquicklinks: {
        title: 'Quick Links',
        links: [
            {
                path: '/about',
                text: 'about us'
            },
            {
                path: '/sign-up',
                text: 'sign up'
            },
            {
                path: '/login',
                text: 'log in'
            },
            {
                path: '/add-listing/new',
                text: 'add listing'
            },
            {
                path: '/contact',
                text: 'contact us'
            },
            {
                path: '/pricing',
                text: 'pricing'
            }
        ]
    },
    footercategories: {
        title: 'Categories',
        links: [
            {
                path: '#',
                text: 'Shops'
            },
            {
                path: '#',
                text: 'Hotels'
            },
            {
                path: '#',
                text: 'Restaurants'
            },
            {
                path: '#',
                text: 'Bars'
            },
            {
                path: '#',
                text: 'Events'
            },
            {
                path: '#',
                text: 'Fitness'
            }
        ]
    },
    footercontact: {
        title: 'Contact With Us',
        lists: [
            {
                icon: <AiOutlineHome />,
                text: '12345 Little Baker St, Melbourne'
            },
            {
                icon: <FiHeadphones />,
                text: '+ 61 23 8093 3400'
            },
            {
                icon: <FaRegEnvelope />,
                text: 'dirto@gmail.com'
            }
        ]
    },
    copyright: {
        menus: [
            {
                path: '#',
                title: 'Terms & Conditions'
            },
            {
                path: '#',
                title: 'Privacy Policy'
            },
            {
                path: '#',
                title: 'Help Center'
            }
        ],
        languages: [
            'English',
            'Bangladesh',
            'Nepal',
            'America',
            'Arabic',
            'England',
        ]
    } */
}

function Footer() {
    return (
        <>
            <section className="footer-area section-bg padding-top-140px padding-bottom-60px">
                <div className="box-icon"></div>
                <div className="box-icon"></div>
                <div className="box-icon"></div>
                <div className="box-icon"></div>
                <div className="box-icon"></div>
                <div className="box-icon"></div>
                <div className="container">
                    <div className="row">
                        <FooterLeftWidget footerleftcnts={footerdata}/>
                        <FooterQuickLinkWidget footerquicklink={footerdata.footerquicklinks} />
                        <FooterCategoryWidget footercategory={sectiondata.footerdata.footercategories} />
                        <FooterContactWidget footercontact={sectiondata.footerdata.footercontact} />
                    </div>

                    {/* Copyright */}
                    {/* <Copyright /> */}
                </div>
            </section>
        </>
    );
}

export default Footer;
