import { combineReducers } from "redux";

import villeReducer from "./villeReducer";
import communeReducer from "./communeReducer";
import cliniqueReducer from "./cliniqueReducer";

export default combineReducers({
    villeReducer,
    communeReducer,
    cliniqueReducer
}); 