import React from 'react'
import { Link } from 'react-router-dom'

export default function Logo({url, className}) {
    return (
        <>
            <Link to="/" className={className}>
                {/* <img src={url} alt="DEPS" />  */}
                <h4>
                    DEPS - Direction des Etablissements et Profession Sanitaire
                </h4>
            </Link>
        </>
    )
}
