import { RETRIEVE_VILLES } from "../actions/types";

const initialState = {
    villes: []
};

function villeReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case RETRIEVE_VILLES:
            return { ...state, villes: payload };
        default:
            return state;
    }
};

export default villeReducer;