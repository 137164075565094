import React, { useEffect, useState } from 'react'
import  { FiSearch } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";

import { retrieveCommunes } from '../../../actions/communeAction';
import { retrieveVilles } from '../../../actions/villeAction';

import communeService from '../../../services/commune.service';
import villeService from '../../../services/ville.service';

export default function BannerOneSearchInput({searchCliniques}) {
    const dispatch = useDispatch()
    
    const [ville_id, setVilleId] = useState(null)
    const [commune_id, setCommuneId] = useState(null)
    
    const villes = useSelector((state) => state.villeReducer.villes)
    const communes = useSelector((state) => state.communeReducer.communes)

    const getVilles = async () => {
        await villeService.getAll().then(function (response) {
            dispatch(retrieveVilles(response.data.liste));
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
            // always executed
        });
    }

    const getCommunes = async () => {
        await communeService.getByVille(ville_id).then(function (response) {
            dispatch(retrieveCommunes(response.data.liste))
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
            // always executed
        });
    }

    useEffect(() => {
        getVilles()
    }, [])

    useEffect(() => {
        if(ville_id !== null){
            getCommunes()
        }
    }, [ville_id])

    function handleSubmit(event){
        event.preventDefault();
        searchCliniques(ville_id, commune_id);
    }
    
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="main-search-input">
                    <div className="main-search-input-item location">
                        <Select
                            name="ville"
                            placeholder="Villes"
                            options={villes}
                            getOptionLabel={(option)=>option.nom_ville}
                            getOptionValue ={(option)=>option.id_ville}
                            onChange={ (obj) => { setVilleId(obj.id_ville) } }
                        />
                    </div>
                    <div className="main-search-input-item location">
                        <Select
                            name="commune"
                            placeholder="Communes"
                            options={communes}
                            getOptionLabel={(option)=>option.nom_ville}
                            getOptionValue ={(option)=>option.id_ville}
                            onChange={ (obj) => { setCommuneId(obj.id_ville) } }
                        />
                    </div>
                    <div className="main-search-input-btn">
                        <button className="button theme-btn" type="submit">
                            Rechercher
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}
