import React, { useState } from 'react';
import GeneralHeader from "../../components/common/GeneralHeader";
import Banner4 from "../../components/banner/banner4/Banner4";
import SectionsHeading from "../../components/common/SectionsHeading";
import BrowseCategoriesTwo from "../../components/other/categories/BrowseCategoriesTwo";
import PlaceOne from "../../components/places/PlaceOne";
import FunFactsOne from "../../components/other/funfacts/funfacts1/FunFactsOne";
import InfoBox3 from "../../components/other/infoboxes/InfoBox3";
import Button from "../../components/common/Button";
import Testimonial from "../../components/sliders/Testimonial";
import SectionDivider from "../../components/common/SectionDivider";
import LatestBlog from "../../components/blogs/LatestBlog";
import CtaOne from "../../components/other/cta/CtaOne";
import ClientLogo from "../../components/sliders/ClientLogo";
import NewsLetter from "../../components/other/cta/NewsLetter";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import sectiondata from "../../store/store";
import InfoBoxOne from "../../components/other/infoboxes/infobox1/InfoBoxOne";
import { useDispatch, useSelector } from 'react-redux';
import cliniqueService from '../../services/clinique.service';
import { retrieveCliniques } from '../../actions/cliniqueAction';
import { Link } from 'react-router-dom';
import BrowseCategories from '../../components/other/categories/BrowseCategories';
import HowItWorkOne from '../../components/hiw/hiw1/HowItWorkOne';
import search from '../../assets/images/search.png';
import sms from '../../assets/images/sms.jpg';
import calendar from '../../assets/images/calendar.png';
import rappel from '../../assets/images/rappel.png';
import salle from '../../assets/images/salle.png';
import folder from '../../assets/images/folder.png';
import msg from '../../assets/images/msg.png';
import send from '../../assets/images/boite-de-reception.png';
import attente from '../../assets/images/salle-dattente.png';
import apmed from '../../assets/images/apmed.png';
import appp from '../../assets/images/appp.png';
import tele from '../../assets/images/tele.png';

var fonctionnalites = [
    {
        id: 1,
        icon: calendar,
        title: 'Plus de rendez vous',
        cardLink: '#'
    },
    {
        id: 2,
        icon: rappel,
        title: 'Rappel',
        cardLink: '#'
    },
    {
        id: 3,
        icon: apmed,
        title: 'Application medecin',
        cardLink: '#'
    },
    {
        id: 4,
        icon: appp,
        title: 'Application patient',
        cardLink: '#'
    },
    {
        id: 5,
        icon: salle,
        title: 'Salle d\'attente virtuel',
        cardLink: '#'
    },
    {
        id: 6,
        icon: folder,
        title: 'Dossier medical numérique',
        cardLink: '#'
    },
    {
        id: 7,
        icon: msg,
        title: 'Messagerie',
        cardLink: '#'
    },
    {
        id: 8,
        icon: send,
        title: 'Envoie & reception de document',
        cardLink: '#'
    },
    {
        id: 9,
        icon: attente,
        title: 'Gestion des salles d\'attente',
        cardLink: '#'
    },
    {
        id: 10,
        icon: tele,
        title: 'Tele-consultation',
        cardLink: '#'
    }
]

var stats = [
    {
        id: '1',
        start: 0,
        end: 150,
        duration: '8',
        separator: '',
        decimal: ',',
        prefix: ' ',
        suffix: '+',
        title: 'Cliniques'
    },
    {
        id: '2',
        start: 0,
        end: 3040,
        duration: '8',
        separator: '',
        decimal: ',',
        prefix: ' ',
        suffix: '+',
        title: 'Centres de sante'
    },
    {
        id: '3',
        start: 0,
        end: 2020,
        duration: '8',
        separator: '',
        decimal: ',',
        prefix: ' ',
        suffix: '+',
        title: 'Medecins'
    },
    {
        id: '4',
        start: 0,
        end: 350,
        duration: '8',
        separator: '',
        decimal: ',',
        prefix: ' ',
        suffix: '+',
        title: 'Nombre de rendez vous'
    }
]

var workItem = [
    {
        id: 1,
        icon: search,
        title: 'Recherchez votre clinique par ville et commune',
        description: 'Sélectionnez la ville et la commune dans laquelle se trouve la clinique dans laquelle vous voulez prendre rendez-vous.'
    },
    {
        id: 2,
        icon: calendar,
        title: 'Prenez rendez-vous directement en ligne',
        description: 'Cliquez sur la clinique pour accéder au site internet et suivez le processus de prise de rendez-vous. Vous recevrez un sms de confirmation de votre rendez-vous.'
    },
    {
        id: 3,
        icon: msg,
        title: 'SMS de rappel sera envoyé avant le RDV',
        description: 'Un sms de rappel de rendez-vous vous sera envoyé la veille de votre rendez-vous.'
    }
]

function Home4() {
    const dispatch = useDispatch()

    const cliniques = useSelector((state) => state.cliniqueReducer.cliniques)

    const getCliniques = async (ville_id, commune_id) => {
        await cliniqueService.getAll(commune_id).then(function (response) {
            dispatch(retrieveCliniques(response.data.liste))
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
            // always executed
        });
    }

    const searchCliniques = async (ville_id, commune_id) => {
        getCliniques(ville_id, commune_id);
    }

    return (
        <main className="home-4">
            {/* Header */}
            <GeneralHeader />

            {/* Banner */}
            <Banner4 
                videoUrl={sectiondata.herobanners.banner4.videobgsrc} 
                title={sectiondata.herobanners.banner4.sectitle} 
                content={sectiondata.herobanners.banner4.seccontent} 
                searchCliniques={searchCliniques} 
            />

            <section className="card-area text-center padding-top-100px padding-bottom-100px">
                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title="Liste des cliniques" titleClass=" before-none pt-0" desc="Vos cliniques par zone" />
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        {
                            cliniques.map(item => (
                                <div className="col-lg-4 column-td-6" key={item.identifiant_entite}>
                                    <div className="card-item">
                                        <Link to="" className="card-image-wrap">
                                            <div className="card-image">
                                                <img src="" className="card__img" alt="item.nom_entite" />
                                            </div>
                                        </Link>
                                        <div className="card-content-wrap">
                                            <div className="card-content">
                                                <a href='https://clinique-honore-cave.com/'>
                                                    <h4 className="card-title">
                                                        {item.nom_entite}
                                                    </h4>
                                                </a>
                                                <ul className="info-list padding-top-20px">
                                                    <li>
                                                        <span className="d-inline-block cordonne"> Ville: </span> {item.nom_ville}
                                                    </li>
                                                    <li>
                                                        <span className="d-inline-block cordonne"> Adresse: </span> {item.adresse_entite}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>

            <section className="hiw-area text-center padding-top-100px padding-bottom-110px">
                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title="Comment ça marche" desc="" />
                    </div>

                    <HowItWorkOne hiw1content={workItem} />
                </div>
            </section>

            <section className="hero-catagory section-bg padding-top-100px padding-bottom-80px text-center">
                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title="Nos Fonctionnalités" desc="" />
                    </div>

                    <BrowseCategories categories={fonctionnalites} />
                </div>
            </section>

            <section className="funfact-area section-bg-2 padding-top-100px padding-bottom-50px text-center margin-bottom-80px">
                <div className="container">
                    <div className="row section-title-width">
                        <SectionsHeading title="Notre annuaire" titleClass="text-white before-none pt-0" desc="" />
                    </div>

                    <FunFactsOne countitems={stats} />
                </div>
            </section>

            {/* NewsLetter */}
            <NewsLetter />

            {/* Footer */}
            <Footer />

            <ScrollTopBtn />

        </main>
    );
}

export default Home4;
